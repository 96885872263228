import modules from "./_modules";
if (document.getElementById("desktopToggleSearch")) {
    document.getElementById("desktopToggleSearch").addEventListener("click", toggleSearch);

    function toggleSearch() {
        var element = document.getElementById("searchInput");
        element.classList.toggle("active");
    }
}

modules();
