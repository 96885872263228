const customInputFile = (inputSelector) => {
    const input = document.querySelector(inputSelector);

    if(!input) return;

    const inputFileName = document.querySelector('.right__label');
    const inputFileNameText = inputFileName.querySelector('p');
    const inputFileNameDelete = inputFileName.querySelector('i.fa-backspace');
    const inputFileNameUpload = inputFileName.querySelector('i.fa-upload');
    inputFileNameDelete.addEventListener('click', () => {
        inputFileName.classList.remove('main-form_file--is-active');
        inputFileName.classList.remove('danger');
        input.value = '';
        inputFileNameText.innerHTML = 'Anexar Currículo';
        inputFileNameDelete.style.display = 'none';
        inputFileNameUpload.style.display = 'block';
    });

    input.addEventListener('change', function(e) {
        let { name: fileName, type } = this.files[0];

        if(type !== 'application/pdf' && type !== 'application/doc') {
            inputFileName.classList.add('main-form_file--is-active');
            inputFileName.classList.add('danger');
            inputFileNameText.innerHTML = 'Formato não suportado!';
            inputFileNameDelete.style.display = 'none';
            inputFileNameUpload.style.display = 'block';
        } else {
            inputFileName.classList.remove('danger');
            inputFileName.classList.add('main-form_file--is-active');
            inputFileNameDelete.style.display = 'block';
            inputFileNameUpload.style.display = 'none';
            inputFileNameText.innerHTML = fileName.split(/[_,\/ -]/).join('-');
        }

    });
};

export default customInputFile;
