import { Swiper, Navigation, Pagination, } from "swiper/dist/js/swiper.esm.js";

Swiper.use([Navigation, Pagination]);

export default () => {
    var swiper = new Swiper('.swiper__container__footer', {
        slidesPerView: 3,
        spaceBetween: 30,
        watchOverflow: true,
        on:{
          init: function(){
            if (this.slides.length < 3){
              this.el.classList.add('is-disabled');
            }
          }
        },
        pagination: {
            el:'.swiper-pagination__footer',
            clickable:true
        },
        breakpoints: {
            1199: {
                slidesPerView: 1
            }
        }
    });
};

var swiper = new Swiper('.banner-slide', {
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '">' + (index + 1) + '</span>';
      },
  },
    watchOverflow: true,
    navigation: {
      nextEl: '.fa-chevron-right',
      prevEl: '.fa-chevron-left',
    },
  });

  var swiper = new Swiper('.gallery-modal-slider', {
    pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
    },
    navigation: {
      nextEl: '.fa-chevron-right',
      prevEl: '.fa-chevron-left',
    },
  });
